import http from "../http-common"

class UserDataService{

    getData(data) {
        return http.post("/usuario/logincus", data);
    }

    Add(data) {
        var persona = {
            per_documento : data.num_documento,
            per_apellidos: data.apellidos,
            per_nombres: data.nombres,
            cant_id: 0,
            per_fecha_nacimiento: "1900-01-01",
            per_sexo: "S",
            per_tipo_documento: data.tipo_doc
        };
        var dataClie = {
            clie_agente_retencion: false,
            clie_exonera_iva: false,
            clie_vender_credito: false,
            plc_id: 13,
            plc_id_anticipo: 95,
            emp_id: 1,
            gpe_id: 1,
            persona: persona,
            clie_password: data.password
        };
        return http.post("/cliente", dataClie);
    }

    getRestore(data) {
        return http.post("/usuario/reset_pass", data);
    }

    getChangePass(data) {
        return http.post("/usuario/change_pass", data);
    }

    async getCheckCode(data) {
        return await http.post("/usuario/check_code", data);
    }

    getById(id) {
        return http.post(`/cliente/id2/${id}`)
    }
}

export default new UserDataService();