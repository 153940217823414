import axios from "axios";

const token = process.env.REACT_APP_API_KEY;

export default axios.create({
  baseURL: process.env.REACT_APP_SERVER_URL,
  headers: {
    "Content-type": "application/json",
    'Authorization': 'Bearer ' + token
  }
});