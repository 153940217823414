import Grid from '@mui/material/Grid2';
import TextField from '@mui/material/TextField';
import { Button, FormControl, RadioGroup, FormControlLabel, Radio } from "@mui/material";
import ProductoDataService from "../services/product.service";
import ConfigDataService from "../services/config.service";
import { useContext, useEffect, useState } from 'react';
import { FormatoNumero } from "../utils/Utility"
import { Link, useNavigate } from 'react-router-dom';
import { IoBagCheckOutline } from 'react-icons/io5';
import OrdenDataService from "../services/order.service";
import UserDataService from '../services/user.service'
import ProcessPay from "../components/ProcessPay";
import { MyContext } from "../App";
import { useFormik } from 'formik';
import * as Yup from 'yup';

const Checkout = () => {

    const [cartData, setCartData] = useState([]);
    const [provinciasData, setProvinciasData] = useState([]);
    const [cantonesData, setCantonesData] = useState([]);
    const [cantones, setCantones] = useState([]);
    const [selectProvinciaId, setSelectProvinciaId] = useState('');
    const [selectCiudadId, setSelectCiudadId] = useState('');
    const [selectedOptionFP, setSelectedOptionFP] = useState('');
    const [isSuccessful, setIsSuccessful] = useState(false);
    const [response, setResponse] = useState(null);
    const [error, setError] = useState(null);

    const history = useNavigate();

    const context = useContext(MyContext);

    const urlSocket = context?.configs?.find(c => c.con_id === 75);

    const formik = useFormik({
        initialValues: {
            per_email: "",
            per_nombres: "",
            per_apellidos: "",
            per_documento: "",
            per_celular: "",
            per_direccion: "",
            tipo_fp: "",
        },
        validationSchema: Yup.object({
            per_nombres: Yup.string().required('Este campo es obligatorio'),
            per_apellidos: Yup.string().required('Este campo es obligatorio'),
            per_email: Yup.string().email('El correo no es valido').required('Este campo es obligatorio'),
            per_documento: Yup.number().typeError('Introduzca un documento válido, por favor').required('Este campo es obligatorio').min(10, 'Introduzca un documento válido'),
            per_celular: Yup.string().required('Este campo es obligatorio'),
            per_direccion: Yup.string().required('Este campo es obligatorio'),
          }),
          onSubmit: (values) => {
            checkout(values);
        },
    });

    const obtenerProvinciasCantones = async () => {
        try {
            const response = await ConfigDataService.getProvinciasCantones();
            setProvinciasData(response.data.provincias);
            //setCantonesData(response.data.cantones);
        } catch (error) {
            console.error('Error al obtener provincias y cantones:', error);
        }
    };

    // Función para obtener datos del cliente
    const obtenerUsuarioData = async () => {
        try {
            const token = localStorage.getItem("authToken");
            if (token !== "" && token !== undefined && token !== null) {
                const user = JSON.parse(localStorage.getItem("user"));
                const response = await UserDataService.getById(user?.userId);
                const persona = response.data;
                //setFormFields(persona);
                formik.setValues(persona);
                const provinciaId = persona?.canton?.prvi_id;
                setSelectProvinciaId(provinciaId);
                if (provinciaId) {
                    handleProvinciasChange(provinciaId);
                    setSelectCiudadId(persona?.canton?.cant_id);
                }
            }
        } catch (error) {
            console.error('Error al obtener datos del cliente:', error);
        }
    };


    useEffect(() => {
        const fetchProductData = async () => {
            try {
                let storedCart = JSON.parse(localStorage.getItem('cart')) || [];
                let updatedCart = [];

                // Fetch the product details for each item in the cart
                for (let item of storedCart) {
                    try {
                        const response = await ProductoDataService.getById(item.pro_id);
                        const productData = response.data;

                        let subtotal = parseFloat(item?.price * item?.quantity)

                        updatedCart.push({
                            ...item,
                            price: productData?.prs_precio, pro_descuento: productData?.pro_descuento, subTotal: parseFloat(subtotal - (subtotal * item?.pro_descuento / 100))
                        });
                    } catch (error) {
                        console.error(`Failed to fetch data for product ID: ${item.pro_id}`, error);
                    }
                }

                // Update localStorage and cart state with updated cart data
                localStorage.setItem('cart', JSON.stringify(updatedCart));
                setCartData(updatedCart);
            } catch (e) {
                console.error("Failed to load or update cart data.", e);
            }
        };

        fetchProductData();

        const init = async () => {
            await obtenerProvinciasCantones();
            await obtenerUsuarioData();
        };

        init();


    }, []);

    /*const onChangeInput = (e) => {
        setFormFields(() => ({
            ...formFields,
            [e.target.name]: e.target.value
        }))
    };*/

    const handleProvinciasChange = (idProv) => {

        setSelectProvinciaId(idProv);

        ConfigDataService.getProvinciasCantones()
            .then((response) => {
                setCantonesData(response.data.cantones);
                const filteredData = response.data.cantones.filter(item => item.provincia.prvi_id === parseInt(idProv));
                setCantones(filteredData);
            })
            .catch(e => {
                console.log(e);
            });
    };

    const handleCiudadChange = (idCant) => {
        setSelectCiudadId(idCant);
    };

    const sendMessage = () => {
        try {
            const socket = new WebSocket(urlSocket?.con_detalle + `/ws?usr_id=1&bd=${process.env.REACT_APP_STATION}`);
            socket.onopen = () => {
                // Envía un JSON con el mensaje
                var message = {
                    mensaje: "",
                    empresa: process.env.REACT_APP_STATION,
                    tipo: "PEDIDOTV-NUEVO"
                };
                socket.send(JSON.stringify(message));
                console.log("JSON enviado al servidor:");
            };
        } catch (error) {
            console.log(error);
        }
    };

    const checkout = (values) => {

        /*if (formFields.per_documento === "") {
            context.setAlertBox({
                open: true,
                error: true,
                msg: "Debe ingresar la cédula"
            })
            return false
        }*/

        if (selectCiudadId === "") {
            context.setAlertBox({
                open: true,
                error: true,
                msg: "Debe seleccionar la ciudad de destino"
            })
            return false
        }

        if (selectProvinciaId === "") {
            context.setAlertBox({
                open: true,
                error: true,
                msg: "Debe seleccionar la provincias de destino"
            })
            return false
        }

        /*if (selectedOptionFP === "") {
            context.setAlertBox({
                open: true,
                error: true,
                msg: "Debe seleccionar una forma de pago"
            })
            return false
        }*/

        var dataCliente = {
            per_email: values.per_email,
            per_nombres: values.per_nombres,
            per_apellidos: values.per_apellidos,
            per_documento: values.per_documento,
            per_celular: values.per_celular,
            idProvincia: selectProvinciaId,
            per_direccion: values.per_direccion,
            tipo_fp: "",
            canton: {
                cant_id: selectCiudadId
            }
        };

        let dataOrden = {
            usr_id: 1,
            tipo_pg: dataCliente.tipo_fp,
            cliente: dataCliente,
            detalleOrden: cartData
        };
        OrdenDataService.save(dataOrden).then((response) => {
            if (response.data.code === 200) {
                localStorage.removeItem('cart');
                context.setAlertBox({
                    open: true,
                    error: false,
                    msg: "Orden registrada con éxito"
                });
                sendMessage();
                history("/");
                window.location.reload();
            } else {
                context.setAlertBox({
                    open: true,
                    error: true,
                    msg: response.data.message
                });
            }
        })
            .catch(e => {
                console.log(e);
            });

    };

    const handleRadioFPChange = (event) => {
        setSelectedOptionFP(event.target.value);
    };

    const request = async () => {
        const path = '/v1/checkouts';
        const data = new URLSearchParams({
            'entityId': '8a829418533cf31d01533d06f2ee06fa',
            'amount': '5.45',
            'currency': 'USD',
            'paymentType': 'DB',
        });

        try {
            const res = await fetch(`https://test.oppwa.com${path}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                    'Authorization': 'Bearer OGE4Mjk0MTg1MzNjZjMxZDAxNTMzZDA2ZmQwNDA3NDh8WHQ3RjIyUUVOWA==',
                },
                body: data.toString(),
            });

            if (!res.ok) {
                throw new Error('Network response was not ok');
            }

            const json = await res.json();
            console.log(json);
            setResponse(json);
            setIsSuccessful(true);
        } catch (err) {
            setError(err.message);
            console.log(err.message);
        }
    };


    return (
        <section className='section'>
            <div className='container'>
                {cartData && cartData?.length > 0 ? (
                    <form className='checkoutForm' onSubmit={formik.handleSubmit}>
                        <div className='row'>
                            <div className='col-xl-7 col-lg-6 col-md-12'>
                                <h5 className='mb-3'>Identificación</h5>
                                <Grid container spacing={2}>

                                    <Grid size={6}>
                                        <TextField
                                            //required
                                            name="per_documento"
                                            value={formik.values.per_documento}
                                            label="Cédula"
                                            fullWidth
                                            size='small'
                                            onBlur={formik.handleBlur}
                                            onChange={formik.handleChange}
                                            error={formik.touched.per_documento && Boolean(formik.errors.per_documento)}
                                            helperText={formik.touched.per_documento && formik.errors.per_documento}
                                        />
                                    </Grid>
                                    <Grid size={6}>

                                    </Grid>
                                    <Grid size={6}>
                                        <TextField
                                            //required
                                            name="per_nombres"
                                            value={formik.values.per_nombres}
                                            label="Nombres"
                                            fullWidth
                                            size='small'
                                            onBlur={formik.handleBlur}
                                            onChange={formik.handleChange}
                                            error={formik.touched.per_nombres && Boolean(formik.errors.per_nombres)}
                                            helperText={formik.touched.per_nombres && formik.errors.per_nombres}
                                        />
                                    </Grid>
                                    <Grid size={6}>
                                        <TextField
                                            //required
                                            name="per_apellidos"
                                            value={formik.values.per_apellidos}
                                            label="Apellidos"
                                            fullWidth
                                            size='small'
                                            onBlur={formik.handleBlur}
                                            onChange={formik.handleChange}
                                            error={formik.touched.per_apellidos && Boolean(formik.errors.per_apellidos)}
                                            helperText={formik.touched.per_apellidos && formik.errors.per_apellidos}
                                        />
                                    </Grid>
                                    <Grid size={6}>
                                        <TextField
                                            //required
                                            name="per_email"
                                            value={formik.values.per_email}
                                            label="Correo"
                                            type='email'
                                            fullWidth
                                            size='small'
                                            onBlur={formik.handleBlur}
                                            onChange={formik.handleChange}
                                            error={formik.touched.per_email && Boolean(formik.errors.per_email)}
                                            helperText={formik.touched.perper_emaildocumento && formik.errors.per_email}
                                        />
                                    </Grid>
                                    <Grid size={6}>
                                        <TextField
                                            //required
                                            name="per_celular"
                                            value={formik.values.per_celular}
                                            label="Celular"
                                            fullWidth
                                            size='small'
                                            onBlur={formik.handleBlur}
                                            onChange={formik.handleChange}
                                            error={formik.touched.per_celular && Boolean(formik.errors.per_celular)}
                                            helperText={formik.touched.per_celular && formik.errors.per_celular}
                                        />
                                    </Grid>
                                </Grid>

                                <h5 className='mt-3 mb-3'>Dirección</h5>
                                <Grid container spacing={2}>
                                    <Grid size={6}>
                                        <FormControl fullWidth>
                                            <select value={selectProvinciaId} onChange={(e) => handleProvinciasChange(e.target.value)} class="form-select">
                                                {provinciasData.map((item, index) => (
                                                    <option key={index} value={item?.prvi_id}>
                                                        {item?.prvi_nombre}
                                                    </option>
                                                ))}
                                            </select>
                                        </FormControl>
                                    </Grid>
                                    <Grid size={6}>
                                        <FormControl fullWidth>
                                            {/*<InputLabel id="cmbCiudad">Ciudad</InputLabel>*/}
                                            <select value={selectCiudadId} onChange={(e) => handleCiudadChange(e.target.value)} class="form-select">
                                                {cantones.map((item, index) => (
                                                    <option key={index} value={item?.cant_id}>
                                                        {item?.cant_nombre}
                                                    </option>
                                                ))}
                                            </select>
                                        </FormControl>
                                    </Grid>
                                    <Grid size={12}>
                                        <TextField
                                            //required
                                            name="per_direccion"
                                            value={formik.values.per_direccion}
                                            label="Calle"
                                            fullWidth
                                            size='small'
                                            onBlur={formik.handleBlur}
                                            onChange={formik.handleChange}
                                            error={formik.touched.per_direccion && Boolean(formik.errors.per_direccion)}
                                            helperText={formik.touched.per_direccion && formik.errors.per_direccion}
                                        />
                                    </Grid>

                                </Grid>

                                {/*<h5 className='mt-3 mb-3'>Pago</h5>
                            <Grid container spacing={2}>
                                <Grid size={6}>
                                    <FormControl>
                                        <RadioGroup
                                            value={selectedOptionFP}
                                            onChange={handleRadioFPChange}
                                        >
                                            <FormControlLabel value="TC" control={<Radio />} label="Tarjeta de crédito" />
                                            <FormControlLabel value="DT" control={<Radio />} label="Transferencia" />
                                        </RadioGroup>
                                    </FormControl>
                                </Grid>
                            </Grid>*/}

                            </div>
                            <div className="col-md-12 offset-xl-1 col-xl-4 col-lg-6">
                                <div class="card shadow-sm">
                                    <h5 class="px-6 mpy-4 bg-transparent mb-0">Resumen de la compra</h5>
                                    <ul class="list-group list-group-flush">
                                        {
                                            cartData?.length !== 0 && cartData?.map((item, index) => {
                                                return (
                                                    <Link key={index} class="list-group-item px-4 py-3" to={`/product/${item?.pro_id}`}>
                                                        <div class="row align-items-center">
                                                            <div class="col-2 col-md-2">
                                                                <img src={ProductoDataService.getPathImage(encodeURIComponent(item?.image?.ima_direccion))} alt={item?.pro_nombre} class="img-fluid" />
                                                            </div>
                                                            <div class="col-5 col-md-5">
                                                                <h6 class="mb-0">{item?.pro_nombre?.substr(0, 30) + '...'}</h6>
                                                                {/*<span><small class="text-muted">.98 / lb</small></span>*/}
                                                            </div>
                                                            <div class="col-2 col-md-2 text-center text-muted">
                                                                <span>{item?.quantity}</span>
                                                            </div>
                                                            <div class="col-3 text-lg-end text-start text-md-end col-md-3">
                                                                <span class="fw-bold">${FormatoNumero(item?.price - (item?.price * (item?.pro_descuento / 100)))}</span>
                                                            </div>
                                                        </div>
                                                    </Link>
                                                )
                                            })
                                        }


                                        <li class="list-group-item px-4 py-3">
                                            <div class="d-flex align-items-center justify-content-between mb-2">
                                                <div>Subtotal</div>
                                                <div class="fw-bold">${FormatoNumero((cartData?.length !== 0 ? cartData.map(item => parseFloat((item.price * item.quantity) - ((item.price * item.quantity) * item.pro_descuento / 100))).reduce((total, value) => total + value, 0) : 0))}</div>
                                            </div>
                                            {/*<div class="d-flex align-items-center justify-content-between">
                                                <div>
                                                    Gastos de envío
                                                    <i class="feather-icon icon-info text-muted" data-bs-toggle="tooltip" title="Default tooltip"></i>
                                                </div>
                                                <div class="fw-bold">$0.00</div>
                                            </div>*/}
                                        </li>

                                        <li class="list-group-item px-4 py-3">
                                            <div class="d-flex align-items-center justify-content-between fw-bold">
                                                <div>Total</div>
                                                <div>${FormatoNumero((cartData?.length !== 0 ? cartData.map(item => parseFloat((item.price * item.quantity) - ((item.price * item.quantity) * item.pro_descuento / 100))).reduce((total, value) => total + value, 0) : 0))}</div>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <Button type="submit" className="btn-blue btn-lg btn-round mt-3"><IoBagCheckOutline /> &nbsp; Pedir ahora</Button>
                    </form>
                ) : (
                    <>
                        <h4>Su carrito está vacio</h4>
                        <p>Para seguir comprando, navegar por las categorías en el sitio, o busque su producto.</p>
                    </>
                )}
                {isSuccessful && <ProcessPay checkId={response?.id} />}
            </div>
        </section>
    );
}
export default Checkout;